import { __decorate } from "tslib";
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { ValidationObserver, ValidationProvider, Validator, } from 'vee-validate';
Validator.extend('required', {
    getMessage: (field) => `${field} deve ser preenchido!`,
    validate: async (value) => !!value,
});
Validator.extend('telefoneJaRegistrado', {
    getMessage: (field) => `${field} deve ser valido!`,
    validate: async (value) => {
        if (value) {
            return value.length === 15;
        }
        return false;
    },
});
Validator.extend('email', {
    getMessage: (field) => `${field} deve ser um email correto!`,
    validate: async (email) => {
        const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return !!re.test(email);
    },
});
let FormDivulgacao = class FormDivulgacao extends Vue {
    constructor() {
        super(...arguments);
        this.formState = {
            name: '',
            email: '',
            phone: '',
            age: null,
        };
        this.showForm = true;
        this.dialogPoliticaPrivacidade = false;
        this.existsPhone = false;
    }
    onChangeFormState(val) {
        this.$emit('input', val);
    }
    get parceria() {
        if (this.propParceria.campanha_id) {
            return this.propParceria.campanha;
        }
        else {
            return this.propParceria;
        }
    }
    openWhatsApp() {
        open(`https://api.whatsapp.com/send?phone=55${this.propParceria.company.unidade_whatsapp}`);
    }
    showPoliticaPrivacidade() {
        this.dialogPoliticaPrivacidade = true;
    }
    async onSubscribe() {
        const isValid = await this.$refs.observer.validate();
        if (!isValid) {
            return;
        }
        await this.propOnSubscribe(this.formState);
        this.showForm = false;
    }
};
__decorate([
    Prop()
], FormDivulgacao.prototype, "value", void 0);
__decorate([
    Prop()
], FormDivulgacao.prototype, "propParceria", void 0);
__decorate([
    Prop({
        type: [Function, Promise],
        default: () => {
            return;
        },
    })
], FormDivulgacao.prototype, "propOnSubscribe", void 0);
__decorate([
    Watch('formState', { deep: true })
], FormDivulgacao.prototype, "onChangeFormState", null);
FormDivulgacao = __decorate([
    Component({
        components: {
            ValidationObserver,
            ValidationProvider,
        },
    })
], FormDivulgacao);
export default FormDivulgacao;
