import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import { parceriaCupomStore, parceriaStore } from '@/utils/store-accessor';
import { newParceriaCupom, } from '@/interfaces/parceria_cupom';
import FormDivulgacao from './FormDivulgacao.vue';
let Divulgacao = class Divulgacao extends Vue {
    constructor() {
        super(...arguments);
        this.valid = true;
        this.signCard = true;
        this.nome = '';
        this.telefone = '';
        this.email = '';
        this.idade = 1;
        this.id = 0;
        this.parceria = null;
        this.parceriaCupomCreate = newParceriaCupom();
    }
    get estilo() {
        const est = {
            'border-radius': '0px',
            'background': this.landingpage.landing_page_background_style,
        };
        if (!this.parceria.landing_page_dobra_titulo ||
            !this.parceria.landing_page_dobra_texto) {
            // @ts-ignore
            est.height = '90%';
        }
        return est;
    }
    get landingpage() {
        if (this.parceria.campanha) {
            return this.parceria.campanha;
        }
        else {
            return this.parceria;
        }
    }
    openWhatsApp() {
        open(`https://api.whatsapp.com/send?phone=55${this.parceria.company.unidade_whatsapp}`);
    }
    async mounted() {
        if (this.$route.params.id) {
            this.id = +this.$route.params.id;
            this.parceria = await parceriaStore.getParceriaDivulgacao(this.id);
            if (!this.parceria) {
                if (window.location.hostname.includes('knn')) {
                    location.href = 'https://www.knnidiomas.com.br/';
                }
                else if (window.location.hostname.includes('phenom')) {
                    location.href = 'https://www.phenomidiomas.com.br/';
                }
            }
        }
    }
    async submit(obj) {
        this.parceriaCupomCreate.nome = obj.name;
        this.parceriaCupomCreate.parceria_id = this.id;
        this.parceriaCupomCreate.telefone = obj.phone;
        this.parceriaCupomCreate.email = obj.email;
        this.parceriaCupomCreate.idade = obj.age;
        await parceriaCupomStore.createParceriaCupomFromLandingPage(this.parceriaCupomCreate);
    }
};
Divulgacao = __decorate([
    Component({
        components: {
            FormDivulgacao,
        },
    })
], Divulgacao);
export default Divulgacao;
